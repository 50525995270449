import './vendor';
import vars from './helpers';
import tilt from 'tilt.js/dest/tilt.jquery';
import 'jquery-validation';
import '../../node_modules/jquery-validation/dist/additional-methods.js';
import '../../node_modules/jquery-validation/dist/localization/messages_ru.js';
import inputmask from 'inputmask/dist/jquery.inputmask';
import select2 from 'select2';
import Swiper, {Navigation, Pagination, EffectFade, Thumbs, Scrollbar} from 'swiper';
import { fancybox } from '@fancyapps/fancybox';
import 'sticky-kit/dist/sticky-kit';
import marquee from 'jquery.marquee';

Swiper.use([Navigation, Pagination, EffectFade, Thumbs, Scrollbar]);

const $document = $(document);

const $window = $(window);

const $html = $('html');

const $body = $('body');

jQuery.extend(jQuery.validator.messages, {
	required: 'Поле не заполнено',
});

$.validator.addMethod(
	'regex',
	function (value, element, regexp) {
		if (regexp && regexp.constructor != RegExp) {
			regexp = new RegExp(regexp[0], regexp[1]);
		} else if (regexp.global) {
			regexp.lastIndex = 0;
		}

		return this.optional(element) || regexp.test(value);
	},
	'Неверный формат',
);

let app = {
	global: {
		init() {
			$('.js-goto').on('click', (e) => {
				let $target = $($(e.currentTarget).data('target') || $(e.currentTarget).attr('href'));

				if ($target.length) {
					e.preventDefault();
					window.scrollTo(0, $target.offset().top - 170);
				}
			});

			$document.on('click', '.js-toggle-class', (e) => {
				e.preventDefault();

				let options = $(e.currentTarget).data();

				switch (options.type) {
					case 'add':
						$html.addClass(options.class);
						break;
					case 'remove':
						$html.removeClass(options.class);
						break;
					case 'toggle':
						$html.toggleClass(options.class);
						break;
					default:
						$html.toggleClass(options.class);
						break;
				}
			});

			$('[data-fancybox]').fancybox({
				touch: false,
				autoFocus: false,
				backFocus: false,
			});

			$('head').append(`<style type="text/css">
				html {
					--compensate-scrollbar:${(window.innerWidth - document.documentElement.clientWidth) / -2}px;
				}
				</style>`);

			$body.css({
				'--width': $window.outerWidth(),
			});
			$window.on('resize', () => {
				$body.css({
					'--width': $window.outerWidth(),
				});
			});

			$('.js-show-hide').on('click', (e) => {
				const $this = $(e.currentTarget);

				const $target = $($this.data('target'));

				e.preventDefault();
				$this.toggleClass('is-active');
				if ($this.hasClass('is-active')) {
					$this.text($this.data('on'));
					$target.removeClass('is-hidden');
				} else {
					$this.text($this.data('off'));
					$target.addClass('is-hidden');
				}
			});

			app.loadMore.init();
			app.analytics();
		},
	},
	vw(px) {
		const base = 1920;
		const baseMobile = 320;

		let output = px;

		if ($window.outerWidth() < 1920) {
			output = px / base * $window.outerWidth();
		}

		if ($window.outerWidth() < 1024) {
			output = px / baseMobile * $window.outerWidth();
		}

		return output;
	},
	
	analytics() {
		$('[data-fancybox]').on('click', (e) => {
			if ($(e.currentTarget).data('eventlabel')) {
				window.eventLabel = $(e.currentTarget).data('eventlabel');
			}
		});

		$document.on('click', '.header__shop a, .benefits__item--shop', (e) => {
			if (typeof dataLayer !== 'undefined') {
				dataLayer.push({
					event: 'Link',
					eventCategory: 'Link',
					eventAction: 'click',
					eventLabel: $(e.currentTarget).attr('href'),
				});
			}
		});

		$document.on('click', '.js-site-link', (e) => {
			if (typeof dataLayer !== 'undefined') {
				dataLayer.push({
					event: 'Link',
					eventCategory: 'Link',
					eventAction: 'click',
					eventLabel: $(e.currentTarget).attr('href'),
				});
			}
		});

		$document.on('click', 'a[href^="tel:"]', (e) => {
			if (typeof dataLayer !== 'undefined') {
				dataLayer.push({
					event: 'Phone',
					eventCategory: 'Phone',
					eventAction: 'click',
					eventLabel: $(e.currentTarget).attr('href').replace('tel:', ''),
				});
			}
		});

		$document.on('click', 'a[href^="mailto:"]', (e) => {
			if (typeof dataLayer !== 'undefined') {
				dataLayer.push({
					event: 'Email',
					eventCategory: 'Email',
					eventAction: 'click',
					eventLabel: $(e.currentTarget).attr('href').replace('mailto:', ''),
				});
			}
		});
	},
	select: {
		init($select) {
			$select.find('select').select2({
				minimumResultsForSearch: $select.data('search') ? 5 : Infinity,
				dropdownParent: $select.find('.input__field'),
				language: {
					noResults: function () {
						return "Ничего не найдено";
					}
				},
			});

			$select.find('select').on('change', () => {
				try {
					$select.find('select').valid();
				} catch {
				}
			});
		},
	},
	header: {
		init($module) {
			$module.find('.header__mobile__menu__open').on('click', (e) => {
				const $this = $(e.currentTarget);
				const $menu = $this.closest('li').find('> ul');

				e.preventDefault();
				$this.toggleClass('is-active');
				if ($this.hasClass('is-active')) {
					$menu.stop().slideDown(400);
				} else {
					$menu.stop().slideUp(400);
				}
			});

			$window.on('scroll.header', () => {
				if ($window.scrollTop() > 2) {
					$module.addClass('is-fixed');
				} else {
					$module.removeClass('is-fixed');
				}
			}).trigger('scroll.header');
		},
	},
	search: {
		init($module) {
			const $input = $module.find('.search__field input');

			const getResult = () => {
				if ($input.val().trim().length > 2) {
					$.ajax({
						type: 'get',
						url: $module.data('ajax'),
						data: {
							q: $input.val(),
						},
						success(result) {
							$module.find('.search__result').html(result);
							$module.addClass('is-show-result');
						},
					});
				} else {
					$module.removeClass('is-show-result');
				}
			};

			let timeout = null;

			$input.on('change keyup', () => {
				clearTimeout(timeout);
				timeout = setTimeout(getResult, 500);
			});
		},
	},
	benefits: {
		init($module) {
			if (!vars.isMobile()) {
				const tilt = $module.find('.benefits__item').tilt({
					maxTilt: 10,
					perspective: 500,
					glare: true,
					maxGlare: 0.5,
				});

				$module.addClass('is-tilted');
			}
		},
	},
	services: {
		init($module) {
			if (!vars.isMobile()) {
				// $module.find('.services__distribution').tilt({
				// 	maxTilt: 10,
				// 	perspective: 500,
				// 	glare: false,
				// 	maxGlare: 0.5,
				// });

				// $module.find('.services__item').tilt({
				// 	maxTilt: 10,
				// 	perspective: 500,
				// 	glare: true,
				// 	maxGlare: 0.5,
				// });
			}
		},
	},
	servicesSlider: {
		init($module) {
			let slider = new Swiper($module.find('.swiper-container')[0], {
				slidesPerView: 1,
				spaceBetween: app.vw(8),
				roundLengths: true,
				navigation: {
					prevEl: $module.find('.carousel__prev')[0],
					nextEl: $module.find('.carousel__next')[0],
				},
				pagination: {
					el: $module.find('.pager-slider')[0],
					clickable: true,
				},
				breakpoints: {
					1024: {
						slidesPerView: 4,
						spaceBetween: app.vw(20),
					},
				},
			});

			$window.on('load', () => {
				$module.find('.services__item').tilt({
					maxTilt: 10,
					perspective: 500,
					glare: true,
					maxGlare: 0.5,
				});
			});
		},
	},
	news: {
		init($module) {
			let previewTimeout = null;

			let slider = new Swiper($module.find('.swiper-container')[0], {
				slidesPerView: 1,
				spaceBetween: app.vw(8),
				navigation: {
					prevEl: $module.find('.carousel__prev')[0],
					nextEl: $module.find('.carousel__next')[0],
				},
				breakpoints: {
					1024: {
						slidesPerView: 3,
						spaceBetween: app.vw(20),
					},
				},
			});

			if (!vars.isMobile()) {
				$module.find('.news__item').hover(
					(e) => {
						let $this = $(e.currentTarget);

						let $preview = $this.find('.news__item__preview');

						clearTimeout(previewTimeout);
						if ($preview.length) {
							previewTimeout = setTimeout(() => {
								$preview.html(`<img src="${$preview.data('src')}">`);
								$preview.addClass('is-show');
							}, 500);
						}
					},
					(e) => {
						let $this = $(e.currentTarget);

						let $preview = $this.find('.news__item__preview');

						clearTimeout(previewTimeout);
						if ($preview.length) {
							$preview.removeClass('is-show');
							$preview.html('');
						}
					},
				);
			}
		},
	},
	pageNews: {
		init($module) {
			$module.find('.page-news__menu__list input').on('click', (e) => {
				let $this = $(e.currentTarget);

				if(vars.isMobile()) {
					$module.find('.page-news__menu__list ul').animate({
						scrollLeft: $this.offset().left - ($window.outerWidth() / 2 - $this.outerWidth() / 2) + $module.find('.page-news__menu__list ul').scrollLeft(),
					}, 400);
				}
			});
		},
	},
	cooperation: {
		init($module) {
			if (!vars.isMobile()) {
				// const tilt = $module.find('.cooperation__item').tilt({
				// 	maxTilt: 10,
				// 	perspective: 500,
				// 	glare: true,
				// 	maxGlare: 0.5,
				// });
			}
		},
	},
	partners: {
		init($module) {
			let slider = new Swiper($module.find('.swiper-container')[0], {
				freeMode: true,
				slidesPerView: 'auto',
				spaceBetween: app.vw(8),
				simulateTouch: false,
				navigation: {
					prevEl: $module.find('.carousel__prev')[0],
					nextEl: $module.find('.carousel__next')[0],
				},
				breakpoints: {
					1024: {
						freeMode: false,
						slidesPerView: 2,
						spaceBetween: app.vw(20),
					},
				},
			});

			$module.find('.partners__line').each((index, line) => {
				$(line).marquee({
					duration: 20000,
					gap: 0,
					delayBeforeStart: 0,
					direction: (index + 1) % 2 === 0 ? 'right' : 'left',
					duplicated: true,
					startVisible: true,
				});
			});
		},
	},
	partnersLine: {
		init($module) {
			let slider = new Swiper($module.find('.swiper-container')[0], {
				slidesPerView: 3,
				spaceBetween: app.vw(10),
				navigation: {
					prevEl: $module.find('.carousel__prev')[0],
					nextEl: $module.find('.carousel__next')[0],
				},
				breakpoints: {
					1024: {
						slidesPerView: 8,
						spaceBetween: app.vw(23),
					},
				},
			});
		},
	},
	comingSoon: {
		init($module) {
			let previewTimeout = null;

			let slider = new Swiper($module.find('.swiper-container')[0], {
				slidesPerView: 'auto',
				slidesPerColumn: 1,
				slidesPerColumnFill: 'row',
				spaceBetween: app.vw(8),
				navigation: {
					prevEl: $module.find('.carousel__prev')[0],
					nextEl: $module.find('.carousel__next')[0],
				},
				breakpoints: {
					1024: {
						slidesPerView: 6,
						slidesPerColumn: 2,
						slidesPerColumnFill: 'row',
						spaceBetween: app.vw(20),
					},
				},
			});

			if (!vars.isMobile()) {
				$module.find('.coming-soon__item').hover(
					(e) => {
						let $this = $(e.currentTarget);

						let $preview = $this.find('.coming-soon__item__preview');

						clearTimeout(previewTimeout);
						if ($preview.length) {
							previewTimeout = setTimeout(() => {
								$preview.html(`<img src="${$preview.data('src')}">`);
								$preview.addClass('is-show');
							}, 500);
						}
					},
					(e) => {
						let $this = $(e.currentTarget);

						let $preview = $this.find('.coming-soon__item__preview');

						clearTimeout(previewTimeout);
						if ($preview.length) {
							$preview.removeClass('is-show');
							$preview.html('');
						}
					},
				);
			}
		},
	},
	goods: {
		init($module) {
			let slider = new Swiper($module.find('.swiper-container')[0], {
				slidesPerView: 1,
				spaceBetween: app.vw(8),
				navigation: {
					prevEl: $module.find('.carousel__prev')[0],
					nextEl: $module.find('.carousel__next')[0],
				},
				breakpoints: {
					1024: {
						slidesPerView: 2,
						spaceBetween: app.vw(20),
					},
				},
			});
		},
	},
	upArrow: {
		init($module) {
			$window.on('scroll.arrow', () => {
				if ($window.scrollTop() > $window.outerHeight()) {
					$module.addClass('is-show');
				} else {
					$module.removeClass('is-show');
				}
			}).trigger('scroll.arrow');

			$module.on('click', (e) => {
				e.preventDefault();
				window.scrollTo(0, 0);
			});
		},
	},
	media: {
		init($module) {
			const $video = $module.find('video');

			$video.on('play', () => {
				$video.prop('controls', true);
				$module.addClass('is-playing');
			});

			$video.on('ended', () => {
				$video.prop('controls', false);
				const src = $video[0].currentSrc;
				const poster = $video[0].poster;

				$video[0].src = '';
				$video[0].src = src;
				$video[0].poster = '';
				$video[0].poster = poster;
				$module.removeClass('is-playing');
			});

			$module.find('.media__video__play').on('click', (e) => {
				e.preventDefault();
				$video[0].play();
			});
		},
	},
	gallery: {
		init($module) {
			const thumbs = new Swiper($module.find('.gallery__thumbs .swiper-container')[0], {
				slidesPerView: 8,
				spaceBetween: app.vw(20),
			});

			const image = new Swiper($module.find('.gallery__image .swiper-container')[0], {
				spaceBetween: app.vw(20),
				navigation: {
					prevEl: $module.find('.gallery__prev')[0],
					nextEl: $module.find('.gallery__next')[0],
				},
				pagination: {
					el: $module.find('.pager-slider')[0],
					clickable: true,
				},
				thumbs: {
					swiper: thumbs,
					autoScrollOffset: 0,
				},
			});

			$window.on('load', () => {
				thumbs.update();
				image.update();
			});
		},
	},
	tabs: {
		init($module) {
			$module.find('.js-tabs-trigger').on('click', (e) => {
				let $this = $(e.currentTarget);

				let $target = $module.find(`.js-tabs-item[data-tab="${$this.data('tab')}"]`);

				e.preventDefault();
				$module.find('.js-tabs-trigger').removeClass('is-active');
				$module.find('.js-tabs-item').removeClass('is-active');
				$this.addClass('is-active');
				$target.addClass('is-active');

				if(vars.isMobile()) {
					$module.find('.tabs__list ul').animate({
						scrollLeft: $this.offset().left - ($window.outerWidth() / 2 - $this.outerWidth() / 2) + $module.find('.tabs__list ul').scrollLeft(),
					}, 400);
				}
			});
		},
	},
	accordion: {
		init($module) {
			const $items = $module.find('.accordion__item');

			$items.on('click', (e) => {
				const $this = $(e.currentTarget);

				const $item = $this.closest('.accordion__item');

				const $content = $item.find('.accordion__item__content');

				e.preventDefault();

				if ($item.hasClass('is-active')) {
					$item.removeClass('is-active');
					$content.slideUp(400);
				} else {
					const $activeItems = $items.filter('.is-active');

					$activeItems.removeClass('is-active');
					$activeItems.find('.accordion__item__content').slideUp(400);
					$item.addClass('is-active');
					$content.slideDown(400);
				}
			});
		},
	},
	mask: {
		init($mask) {
			$mask.inputmask($mask.data('mask').toString(), {
				showMaskOnHover: false,
				clearIncomplete: true,
			});
		},
	},
	form: {
		init($form) {
			let validator = $form.validate({
				lang: 'ru',
				rules: {},

				submitHandler(form) {
					if ($form.data('type') === 'submit') {
						form.submit();
					} else {
						let preparedData;
						let processData;
						let contentType;

						$form.removeClass('is-sended is-error');

						if (!$form.find('.form__submit .button').hasClass('is-loading')) {
							$form.find('.form__submit .button').addClass('is-loading');
							$.ajax({
								type: $form.attr('method'),
								url: $form.attr('action'),
								data: preparedData ? preparedData : $form.serialize(),
								dataType: 'json',
								processData,
								contentType,
								success(result) {
									if (result.result === true) {
										$form[0].reset();
										$.fancybox.close();
									}
									$.fancybox.open(`<div class="modal modal--alert">
										<div class="h3">${result.title}</div>
										<div class="modal__text">${result.message}</div>
									</div>`, {
										touch: false,
										autoFocus: false,
									});
									$form.find('.form__submit .button').removeClass('is-loading');

									if (typeof dataLayer !== 'undefined') {
										dataLayer.push({
											event: $form.data('event'),
											eventCategory: $form.data('event'),
											eventAction: 'send',
											eventLabel: $form.data('event') === 'Feedback' ? window.eventLabel : '',
										});
									}
								},
							});
						}
					}
				},
			});

			setTimeout(() => {
				$form.find('[data-validation]').each((index, item) => {
					$(item).rules('add', $(item).data('validation'));
				});
			}, 1000);
		},
	},
	loadMore: {
		init() {
			if (window.loadMore === 'inited') {
				return false;
			}

			// $('[data-list]').each((index, list) => {
			// 	const $list = $(list);

			// 	if ($list.data('ajax')) {
			// 		$.ajax({
			// 			type: 'get',
			// 			url: $list.data('ajax'),
			// 			success(response) {
			// 				$list.append(response);
			// 			},
			// 		});
			// 	}
			// });

			$document.on('click', '.js-load-more a', (e) => {
				const $this = $(e.currentTarget);
				const $parent = $this.closest('.js-load-more');
				const $list = $(`[data-list="${$parent.data('target')}"]`);
				const offsetTop = $parent.offset().top;

				e.preventDefault();
				if (!$this.hasClass('is-loading')) {
					$this.addClass('is-loading');
					$.ajax({
						type: 'get',
						url: $list.data('ajax'),
						data: {
							page: $this.data('page'),
						},
						success(response) {
							$parent.remove();
							$list.append(response);
							window.scrollTo(0, offsetTop - $('.header').outerHeight() - 50);
						},
					});
				}
			});

			window.loadMore = 'inited';
		},
	},
	cases: {
		init($module) {
			let slider = new Swiper($module.find('.swiper-container')[0], {
				slidesPerView: 1,
				spaceBetween: app.vw(5),
				navigation: {
					prevEl: $module.find('.cases__prev')[0],
					nextEl: $module.find('.cases__next')[0],
				},
				pagination: {
					el: $module.find('.pager-slider')[0],
					clickable: true,
				},
			});
		},
	},
	contactsMap: {
		init($module) {
			const $map = $module.find('.page-contacts__map__container');

			let map = null;

			let options = $module.find('.page-contacts__map__offices a.is-active').data();

			const setItem = ($item) => {
				const data = $item.data();

				map.geoObjects.removeAll();
				map.geoObjects.add(new ymaps.Placemark([data.lat, data.lng], {}, {
					iconLayout: "default#image",
					iconImageHref: data.icon,
					iconImageSize: [93, 93],
					iconImageOffset: [-46, -46],
				}));
				map.setCenter([data.lat, data.lng]);
				$module.find('.page-contacts__map__offices a').removeClass('is-active');
				$module.find('.page-contacts__map__list__item').removeClass('is-active');
				$item.addClass('is-active');
				$module.find(`.page-contacts__map__list__item[data-item="${$item.data('item')}"]`).addClass('is-active');
			};

			app.scriptLoading('//api-maps.yandex.ru/2.1/?lang=ru_RU', () => {
				ymaps.ready(() => {
					map = new ymaps.Map($map[0], {
						center: [options.lat, options.lng],
						zoom: $map.data('zoom'),
						controls: [],
						// controls: ['zoomControl'],
					}, {});

					setItem($module.find('.page-contacts__map__offices a.is-active'));

					$('.page-contacts__map__offices a').on('click', (e) => {
						e.preventDefault();
						setItem($(e.currentTarget));
					});
				});
			});
		},
	},
	newsDetailSlider: {
		init($module) {
			if ($module.find('.swiper-slide').length < 2) {
				$module.find('.pager-slider').hide();
			}
			let slider = new Swiper($module.find('.swiper-container')[0], {
				slidesPerView: 1,
				spaceBetween: app.vw(5),
				allowTouchMove: $module.find('.swiper-slide').length < 2 ? false : true,
				navigation: {
					prevEl: $module.find('.page-news-detail__slider__prev')[0],
					nextEl: $module.find('.page-news-detail__slider__next')[0],
				},
				pagination: {
					el: $module.find('.pager-slider')[0],
					clickable: true,
				},
			});
		},
	},
	partnersList: {
		init($module) {
			$module.find('.js-tabs-trigger').on('click', (e) => {
				let $this = $(e.currentTarget);

				let $target = $module.find(`.js-tabs-item[data-tab="${$this.data('tab')}"]`);

				e.preventDefault();
				$module.find('.js-tabs-trigger').removeClass('is-active');
				$module.find('.js-tabs-item').removeClass('is-active');
				$this.addClass('is-active');
				$target.addClass('is-active');
			});
		},
	},
	stores: {
		init($module) {
			const randomInteger = (min, max) => {
				// получить случайное число от (min-0.5) до (max+0.5)
				let rand = min - 0.5 + Math.random() * (max - min + 1);

				return Math.round(rand);
			}

			$module.find('.js-tabs-trigger').on('click', (e) => {
				let $this = $(e.currentTarget);

				let $target = $module.find(`.js-tabs-item[data-tab="${$this.data('tab')}"]`);

				e.preventDefault();
				$module.find('.js-tabs-trigger').removeClass('is-active');
				$module.find('.js-tabs-item').removeClass('is-active');
				$this.addClass('is-active');
				$target.addClass('is-active');
			});

			$module.find('.page-distribution__stores__item').each((index, item) => {
				const $item = $(item);

				let i = 0;

				const display = () => {
					$item.find('img').removeClass('is-active');
					if ($item.find('img').eq(i).length) {
						$item.find('img').eq(i++).addClass('is-active');
					} else {
						$item.find('img').eq(0).addClass('is-active');
						i = 1;
					}

					setTimeout(display, randomInteger(4000, 8000));
				};

				if ($item.find('img').length > 1) {
					display();
				} else {
					$item.find('img').eq(0).addClass('is-active');
				}
			});
		},
	},
	poster: {
		init($module) {
			let slider = new Swiper($module.find('.swiper-container')[0], {
				slidesPerView: 1,
				spaceBetween: app.vw(5),
				navigation: {
					prevEl: $module.find('.poster__prev')[0],
					nextEl: $module.find('.poster__next')[0],
				},
				pagination: {
					el: $module.find('.pager-slider')[0],
					clickable: true,
				},
			});
		},
	},
	aboutTeam: {
		init($module) {
			$module.find('.js-tabs-trigger').on('click', (e) => {
				let $this = $(e.currentTarget);

				let $target = $module.find(`.js-tabs-item[data-tab="${$this.data('tab')}"]`);

				e.preventDefault();
				$module.find('.js-tabs-trigger').removeClass('is-active');
				$module.find('.js-tabs-item').removeClass('is-active');
				$this.addClass('is-active');
				$target.addClass('is-active');

				if(vars.isMobile()) {
					$module.find('.page-about__team__menu ul').animate({
						scrollLeft: $this.offset().left - ($window.outerWidth() / 2 - $this.outerWidth() / 2) + $module.find('.page-about__team__menu ul').scrollLeft(),
					}, 400);
				}
			});
		},
	},
	aboutShow: {
		init($module) {
			let slider = new Swiper($module.find('.swiper-container')[0], {
				slidesPerView: 1,
				spaceBetween: app.vw(5),
				navigation: {
					prevEl: $module.find('.page-about__show__prev')[0],
					nextEl: $module.find('.page-about__show__next')[0],
				},
				pagination: {
					el: $module.find('.pager-slider')[0],
					clickable: true,
				},
			});
		},
	},
	aboutLife: {
		init($module) {
			let slider = new Swiper($module.find('.swiper-container')[0], {
				slidesPerView: 1,
				spaceBetween: app.vw(25),
				speed: 900,
				navigation: {
					prevEl: $module.find('.page-about__life__prev')[0],
					nextEl: $module.find('.page-about__life__next')[0],
				},
				scrollbar: {
					el: $module.find('.page-about__life__scrollbar')[0],
				},
			});
		},
	},
	aboutHistory: {
		init($module) {
			const progressLengths = 1687;

			const $mobileTab = $module.find('.page-about__history__list-mobile__tab');

			const $sliderProgress = $module.find('.page-about__history__slider__progress');

			const desktop = () => {
				const $rightList = $module.find('.page-about__history__years__right__list');

				const $rightYears = $module.find('.page-about__history__years__right__list__item');

				const $leftList = $module.find('.page-about__history__years__left__list');

				const $leftYears = $module.find('.page-about__history__years__left__list__item');

				const $list = $module.find('.page-about__history__list');

				const $items = $module.find('.page-about__history__list__item');

				const $progress = $module.find('.page-about__history__years__progress');

				let yearHeight = $rightYears.outerHeight();

				let points = {
					years: {},
					centuries: {},
				};

				const getPoints = () => {
					let lastYear = null;
					let lastCentury = null;
					let counter = 0;
					let counter2 = 0;

					points = {
						years: {},
						centuries: {},
					};
					$items.each((index, item) => {
						const $item = $(item);

						if (!lastYear || lastYear !== $item.data('year')) {
							lastYear = $item.data('year');
							points.years[lastYear] = {
								index: counter++,
								key: lastYear,
								value: $item.offset().top,
							};
						}

						if (!lastCentury || lastCentury !== $item.data('century')) {
							lastCentury = $item.data('century');
							points.centuries[lastCentury] = {
								index: counter2++,
								key: lastCentury,
								value: $item.offset().top,
							};
						}
					});
				};

				const findActive = () => {
					let $active = null;

					$items.each((index, item) => {
						const $item = $(item);

						if ($window.scrollTop() + $window.outerHeight() / 2 + yearHeight >= $item.offset().top) {
							$active = $item;
						}
					});

					$items.removeClass('is-active');
					if ($active) {
						$active.addClass('is-active');
					}
				};

				const setYear = () => {
					let findItem = null;

					let y = 0;

					$.each(points.years, (index, item) => {
						if ($window.scrollTop() + $window.outerHeight() / 2 + yearHeight > item.value) {
							findItem = item;
						}
					});

					$rightYears.removeClass('is-active');
					if (findItem && findItem.index !== 0) {
						y = ($window.scrollTop() + $window.outerHeight() / 2 + yearHeight) - findItem.value;

						if (y < yearHeight) {
							$rightList.css({
								transform: `translateY(${-(y + (yearHeight * (findItem.index - 1)))}px)`,
							});
						} else {
							$rightList.css({
								transform: `translateY(${-(yearHeight * findItem.index)}px)`,
							});
						}
						$rightYears.filter(`[data-year="${findItem.key}"]`).addClass('is-active');
					} else {
						$rightList.css({
							transform: 'translateY(0px)',
						});
						$rightYears.eq(0).addClass('is-active');
					}
				};

				const setCentury = () => {
					let findItem = null;

					let y = 0;

					$.each(points.centuries, (index, item) => {
						if ($window.scrollTop() + $window.outerHeight() / 2 + yearHeight > item.value) {
							findItem = item;
						}
					});

					$leftYears.removeClass('is-active');
					if (findItem && findItem.index !== 0) {
						y = ($window.scrollTop() + $window.outerHeight() / 2 + yearHeight) - findItem.value;

						if (y < yearHeight) {
							$leftList.css({
								transform: `translateY(${-(y + (yearHeight * (findItem.index - 1)))}px)`,
							});
						} else {
							$leftList.css({
								transform: `translateY(${-(yearHeight * findItem.index)}px)`,
							});
						}
						$leftYears.filter(`[data-century="${findItem.key}"]`).addClass('is-active');
					} else {
						$leftList.css({
							transform: 'translateY(0px)',
						});
						$leftYears.eq(0).addClass('is-active');
					}
				};

				const setProgress = () => {
					let paddingTop = parseFloat($list.css('padding-top'));

					let paddingBottom = parseFloat($list.css('padding-bottom'));

					let top = $list.offset().top + paddingTop;

					let bottom = top + $list.outerHeight() - paddingTop - paddingBottom;

					let y = ($window.scrollTop() + $window.outerHeight() / 2 + yearHeight) - top;

					if (y <= 0) {
						$progress.css({
							strokeDashoffset: progressLengths,
						});
					} else if (y >= bottom - top) {
						$progress.css({
							strokeDashoffset: progressLengths * 2,
						});
					} else {
						$progress.css({
							strokeDashoffset: progressLengths + (y / (bottom - top) * progressLengths),
						});
					}
				};

				$module.find('.page-about__history__years__container').stick_in_parent({
					parent: $module.find('.page-about__history__container'),
					offset_top: $html.hasClass('is-device-ipad') && $window.outerHeight() > 1200 ? 550 : $('.header').outerHeight(),
				});

				getPoints();

				$window.on('scroll.aboutHistory', () => {
					setYear();
					setCentury();
					findActive();
					setProgress();
				}).trigger('scroll.aboutHistory');

				$window.on('resize', () => {
					setTimeout(() => {
						yearHeight = $rightYears.outerHeight();
						getPoints();
						setYear();
						setCentury();
						findActive();
						setProgress();
					}, 100);
				});
			};

			const slider = new Swiper($module.find('.page-about__history__slider .swiper-container')[0], {
				slidesPerView: 'auto',
				centeredSlides: true,
			});

			slider.on('slideChange', () => {
				const $slide = $(slider.slides[slider.activeIndex]);

				$mobileTab.removeClass('is-active');
				$mobileTab.filter(`[data-year=${$slide.data('year')}]`).addClass('is-active');
				$sliderProgress.css({
					strokeDashoffset: progressLengths + (slider.activeIndex / (slider.slides.length - 1) * progressLengths),
				});
			});

			$window.on('load', () => {
				desktop();
			});
		},
	},
	marketingWork: {
		init($module) {
			let slider = new Swiper($module.find('.swiper-container')[0], {
				slidesPerView: 1,
				spaceBetween: app.vw(10),
				roundLengths: true,
				navigation: {
					prevEl: $module.find('.page-marketing__work__prev')[0],
					nextEl: $module.find('.page-marketing__work__next')[0],
				},
				pagination: {
					el: $module.find('.pager-slider')[0],
					clickable: true,
				},
				breakpoints: {
					1024: {
						slidesPerView: 'auto',
						spaceBetween: app.vw(40),
					},
				},
			});
		},
	},
	localizationRoomTour: {
		init($module) {
			let slider = new Swiper($module.find('.swiper-container')[0], {
				slidesPerView: 1,
				spaceBetween: app.vw(10),
				roundLengths: true,
				navigation: {
					prevEl: $module.find('.page-localization__room-tour__prev')[0],
					nextEl: $module.find('.page-localization__room-tour__next')[0],
				},
				pagination: {
					el: $module.find('.pager-slider')[0],
					clickable: true,
				},
			});
		},
	},
	scriptLoading(src, callback) {
		let script = document.createElement('script');
		let loaded;

		script.setAttribute('src', src);
		if (callback) {
			script.onreadystatechange = script.onload = () => {
				if (!loaded) {
					callback();
				}
				loaded = true;
			};
		}
		document.getElementsByTagName('head')[0].appendChild(script);
	},
};

app.global.init();

window.initApps = () => {
	$('[data-app]').each((index, item) => {
		let $this = $(item);
		let split = $this.data('app').split('||');

		$.each(split, (appIndex, appName) => {
			let appNameCamel = false;

			if (appName.indexOf('-') !== -1) {
				appNameCamel = appName.replace(/(-|\s)(\S)/ug, (m) => m.toUpperCase()).replace(/-/ug, '');
			}

			if (app[appName] && $this.data(`${appName}-init`) !== true) {
				app[appName].init($this);
				$this.data(`${appName}-init`, true);
			} else if (app[appNameCamel] && $this.data(`${appName}-init`) !== true) {
				app[appNameCamel].init($this);
				$this.data(`${appName}-init`, true);
			}
		});
	});
};

initApps();
